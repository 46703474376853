import './index.css';
import { Arrow } from '../../iconComponents';

const Tabs = (props) => {
  return (
    <>
      <div className="desktop-tabs pt-4">
        <div className="tab-group d-flex flex-row flex-wrap">
          {props.properties.map((property, index) => {
            return (
              <>
                <div
                  key={index}
                  className={`tab-item ${props.tabValue === index + 1 ? 'tabActive' : ''}`}
                  onClick={() => props.setTabValue(index + 1)}
                >
                  { property.title }
                  {props.tabValue === index + 1 && (<div className="bottom-line"></div>)}
                </div>
                <div className="arrow mt-2">
                  {props.properties.length !== index + 1 && <Arrow />}
                </div>
              </>
            )
          })}
        </div>
      </div>
    </>
  );
};

export default Tabs;