import './index.css';
import Compliant from '../../assets/icons/Compliant.svg';
import Cost from '../../assets/icons/Cost.svg';
import Efficient from '../../assets/icons/Efficient.svg';

const WhyEximfiles = () => {
  return (
    <>
      <div className="why-eximfiles-container">
        <div className="gradient-line"></div>
        <div className="why-eximfiles-title">Why eximfiles.io ?</div>
        <div className="why-description">
          Get Everything You Need in One Place with Eximfiles. Affordable Pricing, Unbeatable Features
        </div>
        <div className="why-list">
          <div className="why-card">
            <img src={Compliant} className="why-img" alt="" />
            <div className="why-title">Efficient</div>
            <div className="why-desc">
              Streamline your documentation process to eliminate time-consuming data re-entry and costly human errors. Create and share documents quickly.
            </div>
          </div>
          <div className="why-card">
            <img src={Cost} className="why-img" alt="" />
            <div className="why-title">Compliant</div>
            <div className="why-desc">
              With an automated documentation system, you can stay compliant and abide by the rules and regulations of international trade.
            </div>
          </div>
          <div className="why-card">
            <img src={Efficient} className="why-img" alt="" />
            <div className="why-title">Cost-Effective</div>
            <div className="why-desc">
              There is no need to spend money on separate software as eximfiles provide you with a wide range of features at affordable pricing.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyEximfiles;