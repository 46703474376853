import React, { useState, useRef } from 'react';
import './index.css';
import { Logo, Robot, Supercharge } from '../../iconComponents';
import SvgSupercharge from '../../iconComponents/Supercharge';
import PlayIcon from '../../assets/icons/PlayIcon.svg';
import MobLogo from '../../assets/icons/MobLogo.svg';
import Document from '../../assets/icons/Document.svg';
import { Modal, Overlay } from 'react-bootstrap';
import Iframe from 'react-iframe';
import mixpanel from 'mixpanel-browser';
import SvgArrowUp from '../../iconComponents/ArrowUp';
import { useDetectClickOutside } from 'react-detect-click-outside';
import SvgJoinNow from '../../iconComponents/JoinNow';
import SvgMenuIcon from '../../iconComponents/MenuIcon';
mixpanel.init('2c90bf9ed56b3cc33540e2ec9969edf4');

const Landing = (props) => {
  const [show, setShow] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const goToEmailSection = () => {
    if(window.location.hostname === 'eximfiles.io') {
      mixpanel.track('GET_ACCESS', {});
    }
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSe8I7vy18XxEaZdgGxV0oady9TxUu6IXgrLCpAlyJPegB6SaA/viewform?usp=sf_link', '_blank')
  };
  const [showPopover, setShowPopover] = useState(false);
  const target = useRef(null);

  const closeMenu = () => {
    if (showMenu) setShowMenu(!showMenu);
  };
  const ref = useDetectClickOutside({ onTriggered: closeMenu });

  const closeMobileMenu = () => {
    if(showMobileMenu) setShowMobileMenu(!showMobileMenu);
  };
  const mobileRef = useDetectClickOutside({ onTriggered: closeMobileMenu });

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="video-body">
            <Iframe url="https://player.vimeo.com/video/673223073?h=442994f8a5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              id="myId"
              className="video-player"
              display="initial"
              position="relative"
            />
          </div>
        </Modal.Body>
      </Modal>
      {/* <div className="top-message-section">
        <div className="question">Got a global trade question?</div>
        <div className="just-askexim">Just</div>
        <div 
          className="just-askexim" 
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => {
            if(window.location.hostname === 'eximfiles.io') {
              mixpanel.track('OPEN_ASKEXIM', {});
            }
            window.open('https://askexim.co', '_blank')
          }}
        >
          AskEXIM
        </div>
        <Robot width={20} height={18} />
      </div> */}
      <div className="top-message-section" onClick={() => window.open('https://payments.eximfiles.io', '_blank')}>
        🚀 Introducing <span> Eximfiles Payments : </span>Ditch Banking Fees & Endless Paperwork - Get Paid Faster, Export Smarter!
      </div>
      <div className="landing-container">
        <div className="landing-mob-navbar d-md-none d-sm-block">
          <img src={MobLogo} alt="" />
          <div ref={mobileRef} className="d-md-none d-sm-block">
            <div className="menu-icon">
              <SvgMenuIcon width={24} height={24} onClick={() => setShowMobileMenu(!showMobileMenu)} />
            </div>
            {showMobileMenu && (
              <div className="mobile-menu">
                <div className="product-menu">
                  <div>
                    <div ref={ref}>
                      <div className="d-flex justify-content-between align-items-center" onClick={() => setShowMenu(!showMenu)}>
                        <div className="links">Product</div>
                        <div className="links"><SvgArrowUp width={20} height={20} /></div>
                      </div>
                      {showMenu && (
                        <div className="mobile-header-menu">
                          <div 
                            className="header-menu-item" 
                            onClick={() => {
                              props.handleScrollToElement();
                              setShowMenu(false);
                            }}
                          >
                            How does it work?
                          </div>
                          <div className="header-menu-item" style={{ pointerEvents: 'none' }}>Blogs</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="platforms-menu">
                  <div className="platforms">Platforms</div>
                  <div 
                    className="menu-item"
                    onClick={() => {
                      if(window.location.hostname === 'eximfiles.io') {
                        mixpanel.track('OPEN_PAYMENTS', {});
                      }
                      window.open('https://payments.eximfiles.io', '_blank')
                    }}
                  >
                    Eximfiles Payments
                  </div>
                  {/* <div className="menu-item" onClick={openScripXWebsite}>ScripX</div> */}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="landing-navbar">
          <Logo width={148} height={28} />
          <div className="middle-navbar-section">
            <div ref={ref} className="d-none d-md-block">
              <div className="links" onClick={() => setShowMenu(!showMenu)}>
                Product <SvgArrowUp width={20} height={20} />
              </div>
              {showMenu && (
                <div className="header-menu">
                  <div 
                    className="header-menu-item" 
                    onClick={() => {
                      props.handleScrollToElement();
                      setShowMenu(false);
                    }}
                  >
                    How does it work?
                  </div>
                  <div className="header-menu-separator"></div>
                  <div className="header-menu-item" style={{ pointerEvents: 'none' }}>Blogs</div>
                </div>
              )}
            </div>
            {/* <div className="road-to-scripx d-none d-md-block" onClick={() => openScripXWebsite()}>Road to Scripx</div> */}
          </div>
          <div className="end-navbar-section">
            <button 
              className="login-btn d-none d-md-block" 
              onClick={() => {
                if(window.location.hostname === 'eximfiles.io') {
                  mixpanel.track('LOGIN_APP', {});
                }
                window.open('https://app.eximfiles.io');
              }}
            >
              Log In
            </button>
          </div>
          <div className="d-md-none d-sm-block">
            <button 
              className="login-btn" 
              ref={target} 
              onClick={() => {
                setShowPopover(!showPopover);
                setTimeout(() => {
                  setShowPopover(!showPopover);
                }, 3000)
              }}
            >Log In</button>
            <Overlay target={target.current} show={showPopover} placement="bottom" delay={{ show: 250, hide: 400 }}>
              {({ placement, arrowProps, show: _show, popper, ...props }) => (
                <div
                  {...props}
                  style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    padding: '2px 10px',
                    color: 'black',
                    fontSize: '12px',
                    borderRadius: 3,
                    ...props.style,
                  }}
                >
                  Please use a desktop to access the platform.
                </div>
              )}
            </Overlay>
          </div>
        </div>
        <div className="for-exporters">FOR EXPORTERS AND IMPORTERS</div>
        <div className="super-charge">
          <Supercharge width={1118} height={142} />
        </div>
        <h2 className="description">
          From compliant documentation to product catalogs<br /> and remittance tracking - all on one collaborative platform.
        </h2>
        <div className="action-group">
          <button className="get-started" onClick={goToEmailSection}>Get Access</button>
          <button className="watch-video" onClick={handleShow}>
            <img src={PlayIcon} className="play-icon" /> &nbsp; Watch video  
          </button>
        </div>
        <div className="join-now">
          <SvgJoinNow width={397} height={118} />
        </div>
      </div>
      <div className="document-section">
        <img src={Document} className="doc-img" alt="" />
      </div>
    </>
  );
};

export default Landing;