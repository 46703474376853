import './index.css';
import Map from '../../assets/icons/Map.svg';
import Switch from '../../assets/icons/Switch.svg';
import Revolution from '../../assets/icons/Revolution.svg';
import Revolutionize from '../../assets/icons/Revolutionize.svg';
import MobRevolutionize from '../../assets/icons/MobRevolutionize.svg';

const BlueBar = () => {
  const goToEmailSection = () => {
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  };

  return (
    <>
      <div className="blue-bar">
        <div className="blue-bar-content">
          <div className="map-section">
            <img src={Revolution} alt="" className="revolution-img" />
          </div>
          <div className="get-started-section">
            <div className="revolutionize d-none d-md-block">
              <img src={Revolutionize} alt="" className="revolutionize-img" />
            </div>
            <div className="revolutionize d-md-none d-sm-block">
              <img src={MobRevolutionize} alt="" className="revolutionize-img" />
            </div>
            <div className="everything">
              The ultimate solution for all your global trade operations, offering a complete ecosystem that streamlines your business processes and enhance your efficiency.
            </div>
            <button className="get-started-btn" onClick={goToEmailSection}>Get Started</button>
          </div>
        </div>
      </div>
    </>
  );  
};

export default BlueBar;
