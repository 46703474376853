import { useState } from 'react';
import './index.css';
import UpArrow from '../../assets/icons/UpArrow.svg';
import DownArrow from '../../assets/icons/DownArrow.svg';
import CreateIcon from '../../assets/icons/CreateIcon.svg';
import ManageIcon from '../../assets/icons/ManageIcon.svg';
import CollaborateIcon from '../../assets/icons/CollaborateIcon.svg';
import AnalyzeIcon from '../../assets/icons/AnalyzeIcon.svg';

const Accordion = (props) => {
  const [openAccordion, setOpenAccordion] = useState(false);
  const accordionToggle = () => {
    const flag = !openAccordion;
    setOpenAccordion(flag);
    props.setAccordionState(flag);
  };

  return (
    <>
      <div 
        className="accord-body" 
        style={{ 
          marginBottom: openAccordion ? '0' : '18px',
          borderRadius: openAccordion ? '8px 8px 0 0' : '8px',
          boxShadow: openAccordion ? 'none' : '0px 0px 12px rgba(104, 103, 240, 0.48)',
          borderBottom: openAccordion ? 'none' : '1px solid rgba(247, 169, 214, 1)'
        }}
      >
        <header className="accord-header" style={{  }} onClick={accordionToggle}>
          <div className="accord-title">
            { props.heading }
          </div>
          {openAccordion ? (
            <img src={DownArrow} alt="" className="arrow" /> 
          ) : (
            <img src={UpArrow} alt="" className="arrow" />
          )}
        </header>
        {openAccordion && (
          <article className="accord-msg">
            <slot>{ props.label }</slot>
          </article>
        )}
      </div>
    </>
  );
};

export default Accordion;