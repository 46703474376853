export const TabProperties = [
  { 
    title: 'Create',
    header: 'Create documents you can send confidently.',
    info: 'Effortlessly generate accurate, industry-compliant documents with our library of streamlined templates. Save time and streamline your document creation process with Eximfiles.'
  },
  { 
    title: 'Manage',
    header: 'Efficiently organize your documents with perfect management.',
    info: 'Easily store and manage all your trade documents and shipment agreements in one central location with Eximfiles.' 
  },
  { 
    title: 'Collaborate',
    header: 'Collaborate and Stay connected with team members',
    info: 'Collaborate seamlessly with your team on trade documents by tagging them to comments and staying connected with every member involved using Eximfiles.'
  },
  { 
    title: 'Analyze',
    header: 'Analyze your business performance and learn how to improve it.',
    info: 'Analyze your business performance and understand its strengths and weaknesses. Interactive reports help you understand your business growth.'
  },
]