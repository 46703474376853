const Privacy = () => {
  return (
    <>
      <h1 style={{ marginLeft: 320, marginRight: 320, textAlign: "center" }}>&nbsp;</h1>
      <h1 style={{ marginLeft: 320, marginRight: 320, textAlign: "center" }}>
        <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
          <span style={{ fontSize: "23pt" }}>
            <span style={{ color: "#0e101a" }}>Privacy Policy</span>
          </span>
        </span>
      </h1>
      <p style={{ marginLeft: 160, marginRight: 160 }}>
        <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
          <span style={{ fontSize: 22 }}>
            <span style={{ color: "#0e101a" }}>Note</span>
          </span>
        </span>
      </p>
      <p style={{ marginLeft: 160, marginRight: 160 }}>
        <span style={{ fontSize: 14 }}>
          <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
            By using our platform, you acknowledge and agree to abide by the terms and conditions & this privacy policy. If you do not agree with these terms, please refrain from accessing or using our platform.
          </span>
        </span>
      </p>
      <p style={{ marginLeft: 160, marginRight: 160 }}>&nbsp;</p>
      <p style={{ marginLeft: 160, marginRight: 160 }}>
        <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
          <span style={{ fontSize: 22 }}>Information Collection</span>
        </span>
      </p>
      <p style={{ marginLeft: 160, marginRight: 160 }}>
        <span style={{ fontSize: 14 }}>
          <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
            When you engage with our platform or mobile application, we collect and
            store your personal information, which you provide as needed. Our
            primary objective is to offer you a secure, efficient, and personalized
            experience. This enables us to provide services and features tailored to
            your preferences and to enhance the safety and convenience of your
            platform usage.
          </span>
        </span>
      </p>
      <ul>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <strong>Anonymous Browsing</strong>: You can explore the platform
                without revealing your identity. However, once you provide personal
                information, you are no longer anonymous to us. We clearly indicate
                mandatory and optional fields, granting you the choice to withhold
                certain information when using specific platform features.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <strong>User Behavior Tracking</strong>: We may automatically track
                certain details about your platform behavior. This data aids us in
                internal research on user demographics, interests, and behavior,
                enhancing our understanding and ability to protect and serve our
                users. This aggregated information may include the URLs you visit,
                your browser information, and IP address.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <strong>Cookies</strong>: To analyze webpage flow, assess
                promotional effectiveness, and ensure trust and security, we utilize
                'cookies' on select platform pages. These small files are stored on
                your hard drive and assist in delivering our services. While most
                cookies are session-based and automatically deleted, you can decline
                them if your browser allows. Please note that third-party cookies
                are beyond our control.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <strong>Additional Information</strong>: In case of transactions, we
                may collect additional data such as billing details and payment
                information. Information shared in messages, chat rooms, or feedback
                is retained for dispute resolution, customer support, and compliance
                with legal requirements.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <strong>Personal Correspondence</strong>: Personal correspondence
                sent to us, whether by email, letters, or third parties regarding
                your platform activities, may be collected and filed under your
                profile.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                <strong>User Registration</strong>: When you create a free account
                with us, we gather personally identifiable information, including
                your email address, name, phone number, and payment details. While
                certain platform sections allow browsing without registration,
                specific activities necessitate account creation.
              </span>
            </span>
          </p>
        </li>
      </ul>
      <p style={{ marginLeft: 160, marginRight: 160 }}>&nbsp;</p>
      <p style={{ marginLeft: 160, marginRight: 160 }}>
        <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
          <span style={{ fontSize: 22 }}>Use of Information</span>
        </span>
      </p>
      <ul>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                We utilize personal information to:
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                Provide requested services.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                Offer the option to opt-out of marketing communications.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                Resolve disputes and troubleshoot issues.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                Ensure a secure service.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                Process payments and measure consumer interest in our offerings.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                Keep you informed about online and offline promotions, products,
                services, and updates.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                Customize your platform experience.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                Detect and prevent errors, fraud, and illegal activities.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                Enforce our terms and conditions.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p style={{ marginLeft: 160, marginRight: 160 }}>&nbsp;</p>
        </li>
      </ul>
      <p style={{ marginLeft: 160, marginRight: 160 }}>
        <span style={{ fontSize: 22 }}>
          <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
            Demographic and Profile Data Analysis
          </span>
        </span>
      </p>
      <ul>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                We gather and analyze demographic and profile data related to user
                activity on our platform to enhance our products and services
                continually.
              </span>
            </span>
          </p>
        </li>
      </ul>
      <p style={{ marginLeft: 160, marginRight: 160 }}>&nbsp;</p>
      <p style={{ marginLeft: 160, marginRight: 160 }}>
        <span style={{ fontSize: 22 }}>
          <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
            IP Address Usage
          </span>
        </span>
      </p>
      <ul>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                We identify and utilize your IP address for server diagnostics,
                platform administration, and demographic insights.
              </span>
            </span>
          </p>
        </li>
      </ul>
      <p style={{ marginLeft: 160, marginRight: 160 }}>&nbsp;</p>
      <p style={{ marginLeft: 160, marginRight: 160 }}>
        <span style={{ fontSize: 22 }}>
          <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
            Optional Surveys
          </span>
        </span>
      </p>
      <ul>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                Occasionally, we may request optional online survey participation to
                gather contact and demographic information (e.g., zip code, age,
                income level) to enhance your platform experience.
              </span>
            </span>
          </p>
        </li>
      </ul>
      <p style={{ marginLeft: 160, marginRight: 160 }}>&nbsp;</p>
      <p style={{ marginLeft: 160, marginRight: 160 }}>
        <span style={{ fontSize: 22 }}>
          <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>Cookies</span>
        </span>
      </p>
      <ul>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                Cookies are used to facilitate a personalized browsing experience.
                These small pieces of information are stored on your web browser,
                enabling it to recall user-specific data. We employ both permanent
                and temporary cookies without containing personally identifiable
                information.
              </span>
            </span>
          </p>
        </li>
      </ul>
      <p style={{ marginLeft: 160, marginRight: 160 }}>&nbsp;</p>
      <p style={{ marginLeft: 160, marginRight: 160 }}>
        <span style={{ fontSize: 22 }}>
          <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
            Sharing of Personal Information
          </span>
        </span>
      </p>
      <p style={{ marginLeft: 160, marginRight: 160 }}>
        <span style={{ fontSize: 14 }}>
          <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
            We may share personal information:
          </span>
        </span>
      </p>
      <ul>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                With our corporate entities and affiliates to prevent identity
                theft, fraud, and misuse of our services, and to facilitate joint
                services.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                With law enforcement authorities, third-party rights owners, or
                others when reasonably necessary to enforce our terms and policies,
                respond to claims of rights violations, or protect user and public
                safety.
              </span>
            </span>
          </p>
        </li>
        <li style={{ marginLeft: 200, marginRight: 200 }}>
          <p>
            <span style={{ fontSize: 14 }}>
              <span style={{ fontFamily: "Verdana,Geneva,sans-serif" }}>
                In the event of a business merger, acquisition, or reorganization,
                your personal information may be shared/sold with the acquiring
                business entity, which will be required to adhere to this privacy
                policy.
              </span>
            </span>
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
    </>
  )
};

export default Privacy;