import * as React from "react";

const SvgFooterLine = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 1440 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.5}>
      <path stroke="url(#FooterLine_svg__a)" d="M0 .5h726.234" />
      <path stroke="url(#FooterLine_svg__b)" d="M1440 .5H682.596" />
    </g>
    <defs>
      <linearGradient
        id="FooterLine_svg__a"
        x1={290.058}
        y1={2.004}
        x2={-9.814}
        y2={2.004}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E6E98" />
        <stop offset={1} stopColor="currentColor" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="FooterLine_svg__b"
        x1={1137.49}
        y1={-1.004}
        x2={1450.24}
        y2={-1.004}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E6E98" />
        <stop offset={1} stopColor="currentColor" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgFooterLine;
