import * as React from "react";

const SvgArrowUp = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 14.646a.558.558 0 0 1-.396-.164l-5.6-5.6a.56.56 0 1 1 .792-.792l5.204 5.204 5.204-5.204a.56.56 0 1 1 .792.792l-5.6 5.6a.558.558 0 0 1-.396.164Z"
      fill="#F5D872"
    />
  </svg>
);

export default SvgArrowUp;
