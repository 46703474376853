import BlueBar from "./components/BlueBar";
import EmailAccess from "./components/EmailAccess";
import Footer from "./components/Footer/footer";
import Hello from "./components/Hello";
import HowWorks from "./components/HowWorks";
import Landing from "./components/LandingRevamp";
import OtherFeatures from "./components/OtherFeatures";
import Pains from "./components/Pains";
import WhyEximfiles from "./components/WhyEximfiles";
import { useEffect, useRef, useState } from "react";
import './assets/fonts/GorditaRegular.otf'
import mixpanel from 'mixpanel-browser';
mixpanel.init('2c90bf9ed56b3cc33540e2ec9969edf4');

const App = () => {
  const myRef = useRef(null);
  const bottomRef = useRef(null);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const handleScrollToElement = () => {
    myRef.current.scrollIntoView();
  }

  const checkUrlQuery = () => {
    const params = new URLSearchParams(window.location.search);
    if(params.has('inviteCode')) {
      setInviteCode(params.get('inviteCode'));
      bottomRef.current.scrollIntoView();
      setOpenFormModal(true);
    }
  };

  useEffect(() => {
    checkUrlQuery();
    if(window.location.hostname === 'eximfiles.io') {
      mixpanel.track('WEBSITE_VISIT', {});
    }
  })

  return (
    <>
      <Landing handleScrollToElement={handleScrollToElement} />
      <Hello />
      <BlueBar />
      <Pains />
      <div ref={myRef}>
        <HowWorks />
      </div>
      <WhyEximfiles />
      <OtherFeatures />
      <div ref={bottomRef}>
        <EmailAccess showModal={openFormModal} inviteCode={inviteCode} />
      </div>
      <Footer />
    </>
  );
};

export default App;