import React from 'react';

const Sitemap = () => {
  const sitemapContent = `<?xml version="1.0" encoding="UTF-8"?>
  <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
    <!--We manually set the two URLs we know already-->
    <url>
      <loc>https://eximfiles.io</loc>
      <changefreq>Daily</changefreq>
      <priority>1.0</priority>
    </url>
  </urlset>`;

  return (
    <div>
      <pre>{sitemapContent}</pre>
    </div>
  );
};

export default Sitemap;
